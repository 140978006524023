import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import WorkOutlineIcon from '@mui/icons-material/WorkOutline'

// Styles généraux pour les titres
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
`

const Title = styled.h2`
  color: black;
  font-size: 36px;
  margin: 0;
  padding-right: 10px;
  @media (max-width: 768px) {
    font-size: 28px;
  }
`

const Line = styled.div`
  height: 2px;
  background-color: #ccc;
  margin-top: 2px;
`

const ListContainer = styled.div`
  margin: 30px 0;
`

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 0;
  border-bottom: 1px solid #eee;
  @media (max-width: 768px) {
    padding: 15px 0;
  }
`

const SubTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`

const DetailsContainer = styled.div`
  font-size: 16px;
  color: gray;
  margin-left: 20px;
  @media (max-width: 768px) {
    margin-left: 0; // Supprimer la marge gauche sur mobile
  }
`

const Experiences = () => {
  const { t } = useTranslation()

  const experiences = [
    {
      date: '05/2022 - Present',
      position: t('experiences.cdiDeveloppeuseFullStack'),
      company: 'Armor Lux',
      city: 'Quimper',
      missions: [
        t('experiences.conceptionEvolutionMaintenance'),
        t('experiences.tracabiliteAGCE'),
        t('experiences.migrationDrupal'),
        t('experiences.suiviProjetNotion'),
        t('experiences.tutoratStagiaires'),
        t('experiences.aideUtilisateurs'),
      ],
    },
    {
      date: '09/2021 - 10/2021',
      position: t('experiences.cddDeveloppeuse'),
      company: 'ARTGO Média',
      city: 'Larmor-Plage',
      missions: [t('experiences.continuiteMissions')],
    },
    {
      date: '09/2019 - 09/2021',
      position: t('experiences.alternanteDeveloppeuse'),
      company: 'ARTGO Média',
      city: 'Larmor-Plage',
      missions: [
        t('experiences.conceptionDevelopment'),
        t('experiences.ecommerceDevelopment'),
        t('experiences.pluginCreation'),
        t('experiences.seoOptimization'),
        t('experiences.contentIntegration'),
        t('experiences.testingProduction'),
        t('experiences.clientTraining'),
        t('experiences.clientSupport'),
        t('experiences.supportDocuments'),
        t('experiences.proactive'),
        t('experiences.knowledgeBase'),
        t('experiences.tutoring'),
        t('experiences.other'),
      ],
    },
    {
      date: '07/2018 - 09/2019',
      position: t('experiences.alternanteDeveloppeuse'),
      company: 'ARTGO Média',
      city: 'Larmor-Plage',
      missions: [t('experiences.siteUpdates')],
    },
    {
      date: '03/2018',
      position: t('experiences.stageDeveloppeuse'),
      company: '3DS Groupe',
      city: 'Paris',
      missions: [t('experiences.maintenanceUpdates')],
    },
    {
      date: '12/2018',
      position: t('experiences.stageDeveloppeuseLogiciel'),
      company: 'Capgemini',
      city: 'Rennes',
      missions: [
        t('experiences.companyDiscovery'),
        t('experiences.ticketManagement'),
        t('experiences.etlDiscovery'),
      ],
    },
  ]

  return (
    <div
      style={{
        backgroundColor: 'white',
        padding: window.innerWidth < 768 ? '40px 20px' : '20px 60px',
      }}
    >
      <TitleContainer>
        <Title>{t('experiences.title')}</Title>
        <WorkOutlineIcon
          style={{
            color: 'red',
            fontSize: '24px',
            marginLeft: '10px',
            verticalAlign: 'baseline',
          }}
        />
      </TitleContainer>
      <Line />

      <ListContainer>
        {experiences.map((exp, index) => (
          <ItemContainer key={index}>
            <SubTitle>
              {exp.date} - {exp.position}
            </SubTitle>
            <DetailsContainer>
              <div>Société: {exp.company}</div>
              <div>Ville: {exp.city}</div>
              <div>Missions:</div>
              <ul>
                {exp.missions.map((mission, idx) => (
                  <li key={idx}>{mission}</li>
                ))}
              </ul>
            </DetailsContainer>
          </ItemContainer>
        ))}
      </ListContainer>
    </div>
  )
}

export default Experiences
