import React, { useRef, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { keyframes } from 'styled-components'
import backgroundImageAccueil from '../../assets/images/montage_projets.jpg'
import videoAccueil from '../../assets/images_accueil.mp4'
import Button from '../../components/Button/Button'
import { projects } from '../../assets/data/projectsData'
import { Link } from 'react-router-dom'
import './home.css'

// Conteneur principal pour la page d'accueil
const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; // L'image occupe toute la hauteur de la fenêtre
  position: relative;
  margin-top: -70px;
  background: black;
  color: white;

  @media (max-width: 768px) {
    margin-top: -100px;
  }
`

// Section de l'image de fond avec un overlay noir transparent
const HeroSection = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url(${backgroundImageAccueil});
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  padding-top: 0;

  @media (max-width: 768px) {
    background-attachment: scroll;
    height: 80vh;
  }
`

// Superposition sombre pour améliorer la lisibilité du texte
const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
`

// Conteneur pour le texte dans la section d'image
const TextContainer = styled.div`
  position: relative;
  z-index: 2;
  color: white;
  text-align: center;

  @media (max-width: 768px) {
    padding: 0 10px;
  }
`

// Animation pour l'effet de "machine à écrire"
const typing = keyframes`
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
`

// Animation pour le curseur clignotant
const blink = keyframes`
  from, to {
    border-color: transparent;
  }
  50% {
    border-color: white;
  }
`

// Style pour le conteneur du titre
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
`

const TitleHome = styled.h2`
  text-align: left;

  @media (max-width: 768px) {
    padding-top: 20px;
  }
`

// Style pour le titre avec l'animation
const AnimatedTitle = styled.h1`
  font-size: 3rem;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden; /* Pour masquer le texte hors de la zone d'affichage */
  border-right: 4px solid white; /* Le curseur de la machine à écrire */
  width: 0; /* Largeur initiale de 0 pour l'animation */

  /* Animation combinée : texte + curseur */
  animation: ${typing} 4s steps(40, end) forwards,
    ${blink} 0.75s step-end infinite;

  /* Arrête le curseur clignotant après 4s */
  animation-delay: 0s, 4s;
  animation-fill-mode: forwards, forwards;

  @media (max-width: 768px) {
    font-size: 2rem;
    max-width: 280px;
  }
`

const SubText = styled.p`
  margin-top: 20px;
  font-size: 1.5rem;
  opacity: 0.8;
  text-align: center;
  text-decoration: italic;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`

const ContentSection = styled.div`
  text-align: center;
  padding: 20px 40px;
  margin-top: -45px;
  padding: 0 150px;

  @media (max-width: 1200px) {
    padding: 20px 80px;
  }

  @media (max-width: 768px) {
    padding: 20px 40px;
  }

  @media (max-width: 480px) {
    padding: 10px;
  }
`

// Styles pour les sections
const Section = styled.div`
  margin: 40px 0;

  @media (max-width: 768px) {
    margin: 30px 0;
  }
`

const ProjectCard = styled.div`
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  padding: 20px;
  margin: 30px;
  position: relative;
  width: 150px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.4s ease-in-out;
  cursor: pointer; /* Curseur pour indiquer que c'est cliquable */
  opacity: 0.8;

  &:hover {
    transform: scale(1.05);
    opacity: 1;
  }

  @media (max-width: 768px) {
    width: 130px;
    height: 180px;
    margin: 20px;
  }

  @media (max-width: 480px) {
    width: 100px;
    height: 150px;
    margin: 15px;
  }
`

// Style pour la vidéo de fond
const VideoBackground = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;

  @media (max-width: 768px) {
    display: none;
  }
`

// Style pour l'image de fond après la vidéo
const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${backgroundImageAccueil});
  background-size: cover;
  background-position: center;
  z-index: 1;

  @media (min-width: 769px) {
    display: none;
  }
`

const ButtonAccueil = styled(Link)`
  background: rgba(128,128,128,0.4);
  color: rgb(255,255,255);
  font-size: 1rem;
  font-weight: bold;
  padding: 0.7rem 1.5rem;
  border-radius: 0.25rem;
  text-decoration: none;
  font-family:'Roboto'
  transition: background-color 0.3s ease;

  @media (max-width: 768px) {
    display:block;
    margin-top:25px
  }

  
`
const ProjectNumber = styled.span`
  position: absolute;
  bottom: 0px;
  left: -30px;
  font-size: 100px;
  font-family: 'Roboto';
  line-height: 1;
  display: inline-block;
  height: 1em;
  font-weight: bold;
  color: rgb(0, 0, 0);
  -webkit-text-stroke: 0.15rem rgb(255, 255, 255);
  text-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.5);

  @media (max-width: 768px) {
    left: -20px;
    bottom: -20px;
  }
`

const QualityCard = styled.div`
  background: linear-gradient(149deg, #192247 0%, #210e17 96.86%);
  border-radius: 1rem;
  position: relative;
  padding: 20px;
  margin: 15px;
  position: relative;
  width: 18%;
  min-height: 150px;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (max-width: 768px) {
    width: auto;
    min-height: initial;
  }
`

const QualityText = styled.div`
  margin-top: 1.5rem;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1rem;
  font-weight: 400;
`

const QualityTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  padding: 0;
`

const Home = () => {
  const { t } = useTranslation()
  const videoRef = useRef(null)
  const [showImage, setShowImage] = useState(false)

  // Une fois que la vidéo est terminée, on affiche l'image
  const handleVideoEnd = () => {
    setShowImage(true)
  }

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play()
    }
  }, [])

  return (
    <div>
      <HomeContainer>
        {/* Section avec l'image de fond et le texte */}
        <HeroSection>
          {/* Affiche la vidéo seulement si elle n'est pas terminée */}
          {!showImage && (
            <VideoBackground
              ref={videoRef}
              src={videoAccueil}
              type="video/mp4"
              autoPlay
              muted
              onEnded={handleVideoEnd}
            />
          )}
          {/* Si la vidéo est terminée, affiche l'image */}
          {showImage && <BackgroundImage />}
          {/* Affiche l'image de fond directement sur mobile */}
          <BackgroundImage />
          <Overlay /> {/* Superposition noire transparente */}
          {/* Texte au-dessus de la vidéo ou de l'image */}
          <TextContainer>
            <TitleContainer>
              <AnimatedTitle>{t('home.title')}</AnimatedTitle>
              <SubText>
                {t('home.subText')} <br /> ALLAIN Audrey
              </SubText>
            </TitleContainer>
          </TextContainer>
        </HeroSection>

        {/* Section de séparation */}
        <div className="test">
          <div className="separation">
            <div className="curve-container">
              <div className="separation-background"></div>
            </div>
          </div>
        </div>

        {/* Section pour le reste du contenu de la page */}
        <ContentSection>
          <Section>
            <div
              className="apropos"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div style={{ flex: 1 }}>
                <h2
                  style={{
                    margin: '0px',
                    paddingBottom: '0px',
                    textAlign: 'left',
                  }}
                >
                  {t('home.discoverProfile')}
                </h2>
                <p
                  style={{
                    padding: '0px',
                    textAlign: 'left',
                    margin: '0px',
                    fontSize: '16px',
                    paddingRight: '40px',
                  }}
                >
                  {t('home.profileDescription')}
                </p>
              </div>

              <div
                style={{
                  marginLeft: '20px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <ButtonAccueil to="/experiences">
                  {t('home.learnMore')}
                </ButtonAccueil>
              </div>
            </div>
          </Section>

          <Section>
            <TitleHome>{t('home.trendingProjects')}</TitleHome>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                padding: window.innerWidth < 768 ? '10px 0px' : '25px 0px',
              }}
            >
              {projects.slice(0, 4).map((project, index) => (
                <Link
                  to={`/projects/${project.id}`}
                  key={index}
                  style={{ textDecoration: 'none' }}
                >
                  <ProjectCard key={index} image={project.imageHome}>
                    <ProjectNumber>{index + 1}</ProjectNumber>
                  </ProjectCard>
                </Link>
              ))}
            </div>

            <Button
              to="/projects"
              style={{
                marginTop: '20px',
                outline: 'none',
                textDecoration: 'initial',
                marginTop: window.innerWidth < 768 ? '35px' : '20px',
                display: window.innerWidth < 768 ? 'block' : 'initial',
              }}
            >
              {t('home.seeAllProjects')}
            </Button>
          </Section>

          <Section>
            <TitleHome>{t('home.codeAndMore')}</TitleHome>

            <QualityCard>
              <QualityTitle>
                {t('home.qualities.creativity.title')}
              </QualityTitle>
              <QualityText>{t('home.qualities.creativity.text')}</QualityText>
            </QualityCard>
            <QualityCard>
              <QualityTitle>
                {t('home.qualities.adaptability.title')}
              </QualityTitle>
              <QualityText>{t('home.qualities.adaptability.text')}</QualityText>
            </QualityCard>
            <QualityCard>
              <QualityTitle>
                {t('home.qualities.transmission.title')}
              </QualityTitle>
              <QualityText>{t('home.qualities.transmission.text')}</QualityText>
            </QualityCard>
            <QualityCard>
              <QualityTitle>{t('home.qualities.kindness.title')}</QualityTitle>
              <QualityText>{t('home.qualities.kindness.text')}</QualityText>
            </QualityCard>
          </Section>

          <Section>
            <TitleHome>{t('home.needDeveloper')}</TitleHome>
            <p>{t('home.contactMe')}</p>
            <div
              style={{
                display: 'flex',
                gap: '10px',
                marginTop: '20px',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: window.innerWidth < 768 ? 'column' : 'initial',
              }}
            >
              <Button
                to="/contact"
                style={{
                  marginTop: '20px',
                  outline: 'none',
                  textDecoration: 'initial',
                  display: window.innerWidth < 768 ? 'block' : 'initial',
                }}
              >
                {t('home.formulaire')}
              </Button>
              <Button
                to="/profil"
                style={{
                  marginTop: '20px',
                  outline: 'none',
                  textDecoration: 'initial',
                  display: window.innerWidth < 768 ? 'block' : 'initial',
                }}
              >
                {t('home.coordonnees')}
              </Button>
            </div>
          </Section>
        </ContentSection>
      </HomeContainer>
    </div>
  )
}

export default Home
