import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import frFlag from '../../assets/images/france_fr.png' // Chemin de votre drapeau français
import enFlag from '../../assets/images/anglais.png'

// Conteneur pour le switcher
const SwitcherContainer = styled.div`
  display: flex;
  align-items: center;
  color: white; // Assurer que le texte est visible sur fond noir
`

// Image des drapeaux
const FlagImage = styled.img`
  height: 24px; // Ajustez la taille du drapeau
  margin-right: ${(props) =>
    props.marginRight || '0'}; // Ajouter un margin-right optionnel
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1); // Légère animation au survol
  }
`

const LanguageSwitcher = ({ selectedLang, setLang, flagMargin }) => {
  const { i18n } = useTranslation()

  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng)
    setLang(lng) // Mettez à jour la langue choisie
    localStorage.setItem('language', lng) // Mémoriser la langue sélectionnée dans le localStorage
  }

  return (
    <SwitcherContainer>
      <FlagImage
        src={selectedLang === 'fr' ? enFlag : frFlag}
        alt={selectedLang === 'fr' ? 'English' : 'Français'}
        onClick={() =>
          handleLanguageChange(selectedLang === 'fr' ? 'en' : 'fr')
        }
        marginRight={flagMargin}
      />
    </SwitcherContainer>
  )
}

export default LanguageSwitcher
