import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import SubscriptionsIcon from '@mui/icons-material/Subscriptions'
import avatarImage from '../../assets/images/avatar.png'
import avatarImage1 from '../../assets/images/avatar1.png'
import avatarImage3 from '../../assets/images/avatar3.png'
import avatarImage2 from '../../assets/images/avatar2.png'
import avatarImage4 from '../../assets/images/ALLAIN_AUDREY.JPG'
import EditIcon from '@mui/icons-material/Edit'
import LanguageSwitcher from '../../components/LanguageSwitcher/LanguageSwitcher'

// Styles généraux pour les titres
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
`

const Title = styled.h2`
  color: black;
  font-size: 36px;
  margin: 0;
  padding-right: 10px;
`

const Line = styled.div`
  height: 2px;
  background-color: #ccc;
  margin-top: 2px;
`

const ListContainer = styled.div`
  margin: 30px 0;
`

const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 25px 0;
  border-bottom: 1px solid #eee;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const SubTitle = styled.div`
  font-size: 24px;
  flex: 0 0 150px;
  @media (max-width: 768px) {
    flex: inherit;
  }
`

const DetailsContainer = styled.div`
  flex: 1;
  padding-left: 60px;
  @media (max-width: 768px) {
    padding-left: 0px;
    padding-top: 15px;
  }
`

const Name = styled.div`
  font-weight: bold;
`

const Infos = styled.div`
  font-size: 16px;
  color: gray;
`

const ProfileImageContainer = styled.div`
  position: relative;
  display: inline-block;
`

const ProfileImage = styled.img`
  height: 70px;
  width: 70px;
  border-radius: 4px;
  cursor: pointer;
  border: 2px solid #ccc;
  transition: transform 0.3s;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.1);
    border-color: red;
  }
`

const OptionImage = styled.img`
  height: 70px;
  width: 70px;
  border-radius: 4px;
  cursor: pointer;
`

// Empêche la prop `show` d'être passée dans le DOM
const ImageOptions = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'show',
})`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  margin-top: 10px;
  gap: 10px;
`

const EditIconStyled = styled(EditIcon)`
  position: absolute;
  bottom: 10px;
  right: 5px;
  color: white;
  background-color: red;
  border-radius: 50%;
  padding: 2px;
  font-size: 14px !important;
`

function Profil({ profilePic, setProfilePic, selectedLang, setLang }) {
  const [showOptions, setShowOptions] = useState(false) // État pour gérer l'affichage des options d'images

  const handleProfileClick = () => {
    setShowOptions((prev) => !prev) // Alterner l'affichage des options
  }

  const handleImageChange = (newImage) => {
    setProfilePic(newImage)
    setShowOptions(false) // Cacher les options après sélection
  }

  useEffect(() => {
    // Vérifier si une langue est déjà sélectionnée dans le stockage local
    const savedLang = localStorage.getItem('language')
    if (savedLang) {
      setLang(savedLang) // Mettre à jour l'état de la langue avec la valeur sauvegardée
    }
  }, [setLang])

  const { t } = useTranslation()

  return (
    <div
      style={{
        backgroundColor: 'white',
        padding: window.innerWidth < 768 ? '40px 20px' : '20px 60px',
      }}
    >
      {' '}
      {/* Fond blanc pour la page */}
      {/* Titre Comptes */}
      <TitleContainer>
        <Title>{t('profile.comptes')}</Title>
        <SubscriptionsIcon
          style={{
            color: 'red',
            fontSize: '24px',
            marginLeft: '10px',
            verticalAlign: 'baseline',
          }}
        />
      </TitleContainer>
      <Line />
      <ListContainer>
        <ItemContainer>
          <SubTitle>{t('profile.coordonnees')}</SubTitle>
          <DetailsContainer>
            <Name>allain-audrey@hotmail.fr</Name>
            <Link to="/contact">Contactez-moi</Link>
            <Infos>Finistere</Infos>
            <Infos>{t('profile.nationalite')}</Infos>
            <Infos>{t('profile.permis')}</Infos>
          </DetailsContainer>
        </ItemContainer>

        <ItemContainer>
          <SubTitle>{t('profile.profil')}</SubTitle>
          <DetailsContainer>
            <Name>{t('profile.imageProfil')}</Name>
            <ProfileImageContainer>
              <ProfileImage
                src={profilePic}
                onClick={handleProfileClick}
                alt="Profil"
              />
              <EditIconStyled />
            </ProfileImageContainer>
            <ImageOptions show={showOptions}>
              <OptionImage
                src={avatarImage}
                onClick={() => handleImageChange(avatarImage)}
                alt="Option defaut"
              />
              <OptionImage
                src={avatarImage1}
                onClick={() => handleImageChange(avatarImage1)}
                alt="Option 1"
              />
              <OptionImage
                src={avatarImage2}
                onClick={() => handleImageChange(avatarImage2)}
                alt="Option 2"
              />
              <OptionImage
                src={avatarImage3}
                onClick={() => handleImageChange(avatarImage3)}
                alt="Option 3"
              />
              <OptionImage
                src={avatarImage4}
                onClick={() => handleImageChange(avatarImage4)}
                alt="Option 4"
              />
            </ImageOptions>
            <Name>{t('profile.langues')}</Name>
            <LanguageSwitcher
              selectedLang={selectedLang}
              setLang={setLang}
            />{' '}
            {/* Ajout du changeur de langue */}
          </DetailsContainer>
        </ItemContainer>

        <ItemContainer>
          <SubTitle>{t('profile.cherche')}</SubTitle>
          <DetailsContainer>
            <Infos>{t('profile.chercheTexte')}</Infos>
          </DetailsContainer>
        </ItemContainer>

        <ItemContainer>
          <SubTitle>{t('profile.competences')}</SubTitle>
          <DetailsContainer>
            <Infos>{t('profile.autonome')}</Infos>
            <Infos>{t('profile.rigoureuse')}</Infos>
            <Infos>{t('profile.creative')}</Infos>
            <Infos>{t('profile.pedagogue')}</Infos>
            <Infos>{t('profile.forceProposition')}</Infos>
          </DetailsContainer>
        </ItemContainer>
      </ListContainer>
      {/* Titre Certifications
      <TitleContainer>
        <Title>{t('profile.autres')}</Title>
      </TitleContainer>
      <Line />
      {/* Liste des Certifications */}
      {/*
      <ListContainer>
        <ItemContainer>
          <SubTitle>{t('profile.anneeToeic')}</SubTitle>
          <DetailsContainer>
            <Name>{t('profile.toeic')}</Name>
            <Infos>{t('profile.scoreToeic')}</Infos>
            <Infos>{t('profile.organisationToeic')}</Infos>
          </DetailsContainer>
        </ItemContainer>
      </ListContainer> */}
    </div>
  )
}

export default Profil
