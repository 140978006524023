import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationFR from './locales/fr/translation.json';

// Les traductions
const resources = {
    fr: { translation: translationFR },
    en: { translation: translationEN }
};

i18n
    .use(initReactI18next) // Passer i18n par react-i18next
    .init({
        resources,
        lng: "fr", // Langue par défaut
        fallbackLng: "fr", // Langue de secours
        interpolation: {
            escapeValue: false // React se charge déjà d'échapper les valeurs
        }
    });

export default i18n;
