import { Link } from 'react-router-dom'
import styled from 'styled-components'
import backgroundImage from '../../assets/images/autumn.jpg'  // Importation de l'image locale
import Button from '../Button/Button'
import { useTranslation } from 'react-i18next';

// Conteneur principal avec l'image de fond
const ErrorContainer = styled.div`
    height: 100vh;  // Prend la hauteur complète de la fenêtre
    display: flex;
    flex-direction: column;
    justify-content: center;  // Centre verticalement
    align-items: center;      // Centre horizontalement
    background-image: url('${backgroundImage}'); 
    background-size: cover;   // L'image s'adapte à la taille de l'écran
    background-position: center;
    color: white;             // Texte en blanc
    text-align: center;       // Centre le texte
    font-family: 'Roboto', sans-serif;
`

// Conteneur pour le texte avec un fond noir semi-transparent
const TitleBackground = styled.div`
    background-color: rgba(0, 0, 0, 0.5);  // Fond noir semi-transparent
    padding: 10px;
    border-radius: 5px;
`

// Titre stylé
const Title = styled.h1`
    font-size: 48px;
    font-weight:900;
    padding:5px 20px;
    margin:0px
`



function Error() {

    const { t } = useTranslation(); // Récupérer la fonction de traduction

    return (
        <ErrorContainer>
            <TitleBackground>
            <Title>{t('lost_path')}</Title>
            </TitleBackground>
            <Button to="/">{t('back_home')}</Button>
        </ErrorContainer>
    )
}

export default Error