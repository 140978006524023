export const projects = [
  {
    id: 1,
    title: 'Armorlux',
    summary:
      "Développement et évolution d'une plateforme de commande de vêtements professionnels pour des clients de renom tels que la SNCF, FRANCEBOISSONS, PAPREC, et Idex. Ce projet a été une véritable découverte, me confrontant à de nouveaux défis techniques et fonctionnels, tout en développant des solutions sur mesure pour répondre aux besoins spécifiques de l'entreprise et de ses clients. ",
    image: require('../images/projectsImage/armor.png'),
    imageHome: require('../images/projectsImage/armor-list.png'),
    imageBandeau: require('../images/projectsImage/armorlux_banner.png'),
    description: 'Description du projet 1',
    date: '2024',
    category: 'Professionnel',
    langues: ['Angular', 'Symfony', 'TypeScript'],
    languesSecondaires: [
      'GITLAB',
      'CI/CD',
      'Material UI',
      'API REST',
      'RXJS',
      'Vue',
      'Notion',
    ],
    environnement: '',
    body: [
      { type: 'text', text: '' },
      { type: 'image', src: '' },
    ],
    missions: [
      "Maîtrise d'Angular, consolidée par des formations sur OpenClassrooms et Udemy",
      'Utilisation avancée de TypeScript et RXJS pour développer des fonctionnalités réactives, optimisant les performances et la gestion des données asynchrones.',
      "Mise en place et consommation d'API REST entre Angular et Symfony, assurant une synchronisation fluide des données",
      "Mise en place d'une intégration continue (CI/CD) avec GitLab pour assurer des livraisons régulières et fiables",
      'Adoption de Material UI pour concevoir des interfaces esthétiques et ergonomiques, conformes aux standards du web moderne.',
      'Travail étroit avec les équipes métiers pour comprendre leurs besoins spécifiques et concevoir des solutions techniques sur mesure.',
    ],
    images: [
      require('../images/projectsImage/armorlux/etiquettes.png'),
      require('../images/projectsImage/armorlux/fiche_produit.png'),
      require('../images/projectsImage/armorlux/votre_tenue.png'),
    ],
  },
  {
    id: 2,
    title: 'CV Flix : Un portfolio inspiré de Netflix',
    summary:
      "J'ai créé ce portfolio pour me démarquer avec un format original inspiré de l'interface de Netflix. L'objectif était de proposer une présentation moderne et interactive de mon parcours professionnel. En parallèle, c'était l'occasion idéale de découvrir et d'apprendre React !",
    image: require('../images/projectsImage/cvflix.png'),
    imageHome: require('../images/projectsImage/cvflix-list.png'),
    imageBandeau: require('../images/projectsImage/cvflix_banner.png'),
    description: "Création d'un CV en ligne avec React",
    date: '2024',
    category: 'Personnel',
    langues: ['React', 'Material UI', 'JS'],
    languesSecondaires: ['useState, useRef, useEffect'],
    environnement: '',
    body: [
      { type: 'text', text: '' },
      { type: 'video', src: '' },
    ],
    missions: [
      'Découverte approfondie de React et des concepts fondamentaux tels que props, useState, useRef, et useEffect',
      "Ajout d'animations subtiles en utilisant React et CSS",
      "Choix stratégique d'un nom de domaine personnalisé",
      "Sélection et configuration d'un hébergeur fiable (IONOS)",
      'Refactorisation régulière du code pour améliorer la lisibilité, la maintenabilité et réduire les duplications',
    ],
  },
  {
    id: 3,
    title: 'Pharmacie Montagnes Noires',
    summary:
      "Création d'un site de pharmacie moderne et responsive, offrant une expérience utilisateur optimale sur tous les appareils.",
    image: require('../images/projectsImage/pharmacie.png'),
    imageHome: require('../images/projectsImage/pharmacie-list.png'),
    imageBandeau: require('../images/projectsImage/pharmacie_banner.png'),
    description: 'Création site vitrine pour une pharmacie',
    date: '2022',
    category: 'Personnel',
    langues: ['Wordpress', 'PHP', 'CSS'],
    languesSecondaires: [],
    environnement: '',
    body: [
      { type: 'text', text: '' },
      { type: 'image', src: '' },
    ],
    missions: [
      'Analyse approfondie des besoins du client pour concevoir un site web sur-mesure, adapté à ses attentes et à celles de sa clientèle.',
      "Refonte de l'identité visuelle pour refléter une image moderne et professionnelle, tout en renforçant la cohérence de la marque",
      'Optimisation SEO et gestion de la fiche Google MyBusiness pour garantir une visibilité accrue et une meilleure performance dans les résultats de recherche',
    ],
    images: [
      require('../images/projectsImage/pharmacie/pharmacie-1.png'),
      require('../images/projectsImage/pharmacie/pharmacie-2.png'),
      require('../images/projectsImage/pharmacie/pharmacie-3.png'),
    ],
    button: { src: 'https://www.pharmacie-gourin.fr/', text: 'Voir le site !' },
  },
  {
    id: 4,
    title: 'UQAC Chicoutimi, Québec',
    summary:
      "Après l'obtention de mon diplôme, j'ai poursuivi un trimestre en ingénierie informatique à l'UQAC à Chicoutimi, Québec, pour approfondir mes compétences techniques.",
    image: require('../images/projectsImage/canada.png'),
    imageHome: require('../images/projectsImage/quebec-list.png'),
    imageBandeau: require('../images/projectsImage/uqac_banner.png'),
    description: "Petit trimestre au canada au sein de L'UQAC",
    date: '2021',
    category: 'Personnel',
    langues: ['Android', 'Python', 'JS'],
    languesSecondaires: [
      'Gestion de projet informatique',
      'Développement personnel',
    ],
    environnement: '',
    body: [
      { type: 'text', text: '' },
      { type: 'video', src: '' },
    ],
    missions: [
      "Un trimestre d'apprentissage personnel et enrichissant au sein de l'UQAC",
      "Capacité d'adaptation rapide à un nouvel environnement de travail",
      'Travail collaboratif avec des étudiants internationaux sur des projets techniques, favorisant l’échange de compétences et l’enrichissement interculturel.',
      'Suivi de cours avancés en Android, intelligence artificielle et gestion de projet ',
    ],
  },
  {
    id: 5,
    title: 'ARTGO Media : agence web ',
    summary:
      "J'ai acquis trois ans d'expérience en alternance en tant que développeuse web au sein d'ARTGO Média à Larmor-Plage, où j'étais autonome sur mes projets. J'ai réalisé plusieurs projets clients qui ont abouti avec succès, rendant cette expérience à la fois enrichissante et formatrice.",
    image: require('../images/projectsImage/artgomedia.png'),
    imageHome: require('../images/projectsImage/artgomedia-list.png'),
    imageBandeau: require('../images/projectsImage/artgomedia_banner.png'),
    description: "Développeuse au sein d'une agence web",
    date: '2021',
    category: 'Professionnel',
    langues: ['Php', 'JS', 'WordPress', 'WooCommerce'],
    languesSecondaires: [
      'Ajax',
      'optimisation',
      'Gestion de projet',
      'SEO',
      'SEA',
      'CSS/SCSS',
    ],
    environnement: '',
    body: [{ type: 'text', text: '' }],
    images: [
      require('../images/projectsImage/artgomedia/Cobigo.png'),
      require('../images/projectsImage/artgomedia/assurocean.png'),
      require('../images/projectsImage/artgomedia/cheminee-nicolas.png'),
      require('../images/projectsImage/artgomedia/emd.png'),
      require('../images/projectsImage/artgomedia/Law-riant.png'),
      require('../images/projectsImage/artgomedia/leflecer.png'),
      require('../images/projectsImage/artgomedia/parki.png'),
      require('../images/projectsImage/artgomedia/Pluemliau-Bieuzy.png'),
      require('../images/projectsImage/artgomedia/pulse.png'),
    ],
    button: {
      src: 'https://www.artgomedia.com/realisations#site-vitrine',
      text: 'Voir les réalisations',
    },
    missions: [
      'Développement de sites web personnalisés sans utilisation de templates en exploitant WordPress/WOOCommerce et le langage PHP',
      'Respect rigoureux des maquettes graphiques, assurant une correspondance parfaite entre design et implémentation.',
      "Développement de plugins sur mesure en PHP orienté objet, permettant d'ajouter des fonctionnalités uniques aux sites clients",
      'Amélioration continue du SEO pour garantir aux clients un positionnement optimal dans les moteurs de recherche',
      'Formation des utilisateurs',
      'Collaboration avec une diversité de clients, allant des artisans locaux aux mairies et commerces, pour répondre à des besoins variés et spécifiques',
      "Acquisition d'une expertise sur l'accessibilité web, notamment à travers le développement de sites institutionnels, afin de garantir la conformité avec les standards et rendre les sites accessibles à tous",
    ],
  },
  {
    id: 6,
    title: 'D-Pack',
    summary:
      "J'ai participé à la création d'une application de livraison optimisée dans le cadre de ma formation, en travaillant à la fois en autonomie et en groupe. L'objectif était de découvrir de nouvelles techniques et de résoudre un problème spécifique : permettre aux livreurs de choisir le parcours le plus adéquat en termes de temps",
    image: require('../images/projectsImage/dpack.png'),
    imageHome: '',
    imageBandeau: require('../images/projectsImage/dpack_banner.png'),
    description: "Création d'une application de livraison fictive avec Flutter",
    date: '2020',
    category: 'Formation',
    langues: ['Flutter', 'API', 'MAPPING'],
    languesSecondaires: ['Google', 'Communication', 'Méthode Agile'],
    environnement: '',
    body: [
      { type: 'text', text: '' },
      { type: 'video', src: '' },
    ],
    missions: [
      'Adoption de la méthode Agile, avec des sprints courts pour assurer une livraison régulière et incrémentale des fonctionnalités',
      "Développement rapide d'une première version fonctionnelle avec Flutter",
      'ntégration des services de Google Maps via API, optimisant ainsi la gestion des itinéraires pour les livreurs.',
    ],
  },
]
