import styled from 'styled-components'
import { Link } from 'react-router-dom'

const Button = styled(Link)`
    padding: 15px 30px;
    background: rgb(229, 9, 20);
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-size: 18px;
    font-family:'Roboto'
    transition: background-color 0.3s ease;
    margin-top: 20px;

    &:hover {
        background: rgba(229, 9, 20,0.5);
    }
`

export default Button
