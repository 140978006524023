import { Link, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import logoImage from '../../assets/images/logo.png'
import InfoIcon from '@mui/icons-material/Info'
import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher'

const StyledLink = styled(Link)`
  padding: '15px';
  margin: 0 10px;
  color: white;
  text-decoration: none;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  position: relative;
  font-weight: ${(props) => (props.$isActive ? 'bold' : 'normal')};
  border-bottom: ${(props) => (props.$isActive ? '2px solid white' : 'none')};

  &:hover {
    text-decoration: ${(props) => (props.$isActive ? 'initial' : 'underline')};
  }

  @media (max-width: 768px) {
    margin: 10px 0;
    font-size: 14px;
    display: block;
  }
`

// Style du bandeau de navigation
const NavBar = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 50px;
  background: ${({ isHome }) =>
    isHome
      ? 'linear-gradient( 180deg, rgba(0, 0, 0, 0.8000) 0.000%, rgba(0, 0, 0, 0.7889) 8.333%, rgba(0, 0, 0, 0.7556) 16.67%, rgba(0, 0, 0, 0.7000) 25.00%, rgba(0, 0, 0, 0.6222) 33.33%, rgba(0, 0, 0, 0.5222) 41.67%, rgba(0, 0, 0, 0.4000) 50.00%, rgba(0, 0, 0, 0.2778) 58.33%, rgba(0, 0, 0, 0.1778) 66.67%, rgba(0, 0, 0, 0.1000) 75.00%, rgba(0, 0, 0, 0.04444) 83.33%, rgba(0, 0, 0, 0.01111) 91.67%, rgba(0, 0, 0, 0.000) 100.0% )'
      : 'black'};
  position: ${({ isHomePage }) => (isHomePage ? 'absolute' : 'relative')};
  top: 0;
  z-index: 100;

  @media (max-width: 768px) {
    padding: 10px 20px;
    flex-direction: column;
  }
`

// Style du logo à gauche
const Logo = styled.img`
  height: 30px;
  margin-right: 15px;
`

// Nouveau conteneur pour aligner le logo et les liens à gauche
const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`

// Style pour le conteneur de profil (image + flèche)
const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  &:hover > div,
  div:hover {
    display: block;
  }

  &:hover span {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
  }

  @media (max-width: 768px) {
    margin-top: 20px;
  }
`

// Style de l'image du profil
const ProfileImage = styled.img`
  height: 30px;
  margin-right: 15px;
  border-radius: 5px;
`

// Style de la flèche à côté de l'image de profil
const ArrowIcon = styled.span`
  color: white;
  font-size: 12px;
  transition: transform 0.3s ease;
`

const MenuLinks = styled.div`
  display: flex;

  @media (max-width: 768px) {
    display: ${(props) => (props.isOpen ? 'block' : 'none')};
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background-color: black;
    padding: 20px;
    z-index: 100;
  }
`

// Sous-menu cache
const SubMenu = styled.div`
  display: none;
  position: absolute;
  background-color: black;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  top: 30px;
  right: -20px;
  padding: 25px 0px 0px 10px;
  min-width: 150px;
`

const SubMenuLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-size: 16px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }
`

const WhiteIcon = styled(InfoIcon)`
  color: white;
  font-size: 20px;
  vertical-align: middle;
  margin-right: 10px;
`

const LanguageSwitcherStyled = styled(LanguageSwitcher)`
  margin-right: 20px !important;
  padding: 5px;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 4px;

  &:hover {
    border: 2px solid white;
  }

  img {
    margin-right: 10px;
  }
`

const BurgerMenu = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    cursor: pointer;
  }
`

function Header({ profilePic, setProfilePic, selectedLang, setLang }) {
  const location = useLocation() // Utilisation de useLocation pour détecter l'URL actuelle
  const { t } = useTranslation() // Hook pour accéder aux traductions
  const [isMenuOpen, setIsMenuOpen] = useState(false) // État pour le menu burger
  // Déterminez si vous êtes sur la page d'accueil
  const isHome = location.pathname === '/'

  return (
    <NavBar isHome={isHome}>
      <LeftContainer>
        {/* Logo à gauche */}
        <Logo src={logoImage} alt="Logo" />

        {/* Burger Menu pour les petits écrans */}
        <BurgerMenu onClick={() => setIsMenuOpen(!isMenuOpen)}>
          {isMenuOpen ? (
            <CloseIcon style={{ color: 'white' }} />
          ) : (
            <MenuIcon style={{ color: 'white' }} />
          )}
        </BurgerMenu>

        {/* Liens du menu */}
        <MenuLinks isOpen={isMenuOpen}>
          <StyledLink to="/" $isActive={location.pathname === '/'}>
            {t('header.accueil')}
          </StyledLink>
          <StyledLink
            to="/experiences"
            $isActive={location.pathname === '/experiences'}
          >
            {t('header.experiences')}
          </StyledLink>
          <StyledLink
            to="/projects"
            $isActive={location.pathname === '/projects'}
          >
            {t('header.projets')}
          </StyledLink>
          <StyledLink
            to="/formations"
            $isActive={location.pathname === '/formations'}
          >
            {t('header.formations')}
          </StyledLink>
        </MenuLinks>
      </LeftContainer>

      {/* Profil à droite avec flèche */}
      <ProfileContainer style={{ display: 'flex', alignItems: 'center' }}>
        <LanguageSwitcherStyled
          selectedLang={selectedLang}
          setLang={setLang}
          flagMargin="15px"
        />{' '}
        <ProfileImage src={profilePic} alt="profil" />
        <ArrowIcon>▼</ArrowIcon>
        {/* Sous-menu avec un lien "Mon Profil" */}
        <SubMenu>
          <SubMenuLink to="/profil">
            <WhiteIcon /> {t('header.profil')}
          </SubMenuLink>
        </SubMenu>
      </ProfileContainer>
    </NavBar>
  )
}

export default Header
