import { useTranslation } from 'react-i18next' // Importer le hook de traduction
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import FavoriteIcon from '@mui/icons-material/Favorite'

const FooterContainer = styled.footer`
  background-color: black;
  color: white;
  text-align: center;
  padding: 10px;
  font-family: 'Roboto', sans-serif;
`

const FooterLink = styled(Link)`
  color: white;
  text-decoration: none;
  margin-left: 15px;

  &:hover {
    text-decoration: underline;
  }
`

function Footer() {
  const currentYear = new Date().getFullYear() // Obtenir l'année en cours
  const { t } = useTranslation()

  return (
    <FooterContainer>
      © {currentYear} - {t('footer.notFinalized')} -{' '}
      <FavoriteIcon style={{ fontSize: '16px' }} /> -
      <FooterLink to="/contact">{t('footer.contact')}</FooterLink>
    </FooterContainer>
  )
}

export default Footer
