import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { projects } from '../../assets/data/projectsData'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import { useTranslation } from 'react-i18next'

const ProjectsContainer = styled.div`
  padding: 40px 20px 80px 20px;
  background-color: black;
  color: white;
`

// Style pour le titre de la page
const ProjectTitle = styled.h1`
  text-align: center;
  margin-bottom: 80px;
  font-size: 2.5rem;

  @media (max-width: 768px) {
    font-size: 2rem;
    margin-bottom: 40px;
  }
`
// Style pour le conteneur des cartes de projet
const ProjectsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  padding: 0 40px;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 0 20px;
  }
`
// Style de la carte de projet
const ProjectCard = styled(Link)`
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: 10px;
  transition: transform 0.3s ease;
  text-decoration: none;

  &:hover {
    transform: scale(1.05);
  }
`

// Style de l'image du projet
const ProjectImage = styled.img`
  width: 100%;
  height: auto;
  transition: transform 0.5s ease;

  ${ProjectCard}:hover & {
    transform: scale(1.1);
  }
`

// Style pour l'overlay d'informations
const Overlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  opacity: 0;
  text-decoration: none;
  transition: opacity 0.3s ease;

  ${ProjectCard}:hover & {
    opacity: 1; 
`

// Style pour l'icône de lecture
const PlayIcon = styled(PlayCircleOutlineIcon)``

const TitleContainer = styled.h2`
  display: flex;
  align-items: center;
  margin: 0;
`

const ListTechnos = styled.p`
  display: flex;
  align-items: center;
  margin: 0;
`

const Projects = () => {
  const { t, i18n } = useTranslation()

  if (!i18n.isInitialized) {
    return null // ou un loader, ou un message de chargement
  }

  return (
    <ProjectsContainer>
      <ProjectTitle>{t('projects.title')}</ProjectTitle>
      <ProjectsGrid>
        {projects.map((project) => (
          <ProjectCard to={`/projects/${project.id}`} key={project.id}>
            <ProjectImage src={project.image} alt={project.title} />
            <Overlay>
              <TitleContainer>
                <PlayIcon
                  style={{
                    color: 'red',
                    fontSize: '24px',
                    marginRight: '8px',
                    verticalAlign: 'baseline',
                  }}
                />
                {t(project.title)}
                {console.log(project.title)}
                {console.log(t(project.title))}
              </TitleContainer>
              <p>
                {project.date} | {project.category}
              </p>
              <ListTechnos>
                {Array.isArray(project.langues) &&
                  project.langues.map((langue) => (
                    <span
                      style={{
                        fontFamily: 'Roboto',
                        fontWeight: 'bold',
                        margin: '0px 5px',
                      }}
                    >
                      {langue}
                    </span>
                  ))}
              </ListTechnos>
            </Overlay>
          </ProjectCard>
        ))}
      </ProjectsGrid>
    </ProjectsContainer>
  )
}

export default Projects
