import React from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { projects } from '../../assets/data/projectsData'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Autoplay } from 'swiper/modules'
import Button from '../../components/Button/Button'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/bundle'

// Styles pour le conteneur du projet
const ProjectDetailContainer = styled.div`
  background-color: black;
  color: white;
  padding: 20px;
`

// Style de l'image bandeau
const BannerImage = styled.img`
  width: 100%;
  height: 250px;
  object-fit: cover;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  @media (max-width: 768px) {
    height: 150px;
  }
`

// Style pour le contenu du projet
const ContentContainer = styled.div`
  margin-top: 20px;
`

// Style pour les technologies
const TechList = styled.ul`
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    justify-content: space-around;
  }
`

const TechItem = styled.li`
  margin-right: 15px;
  padding: 5px 10px;
  background-color: #222;
  border-radius: 5px;

  @media (max-width: 768px) {
    flex: 1 1 calc(50% - 15px);
    margin-top: 15px;
  }
`

const TechItemImportant = styled.li`
  margin-right: 15px;
  padding: 5px 10px;
  background: #192247;
  border-radius: 5px;
`

// Style pour le résumé
const Summary = styled.p`
  font-size: 1.2rem;
  margin: 10px 0;
  @media (max-width: 768px) {
    font-size: 1rem;
    margin: 0px 0;
  }
`

const CarouselContainer = styled.div`
  width: 100%;
  margin: 40px 0px;

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 300px;
      object-fit: cover;
      border-radius: 20px;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: white; /* Change la couleur des flèches */
    &:after {
      font-size: 20px; /* Taille des flèches */
    }
  }

  .swiper-button-next {
    right: 10px; /* Position ajustée */
  }

  .swiper-button-prev {
    left: 10px; /* Position ajustée */
  }
`

const ProjectDetail = () => {
  const { id } = useParams()

  const selectedProject = projects.find((p) => p.id === parseInt(id))

  if (!selectedProject) {
    return <div>Projet non trouvé</div>
  }

  return (
    <ProjectDetailContainer>
      <BannerImage
        src={selectedProject.imageBandeau}
        alt={selectedProject.title}
      />
      <h1>{selectedProject.title}</h1>
      <p>
        {selectedProject.date} | {selectedProject.category}
      </p>

      <TechList>
        {selectedProject.langues.map((langue) => (
          <TechItemImportant key={langue}>{langue}</TechItemImportant>
        ))}
      </TechList>
      <TechList>
        {selectedProject.languesSecondaires.map((langue) => (
          <TechItem key={langue}>{langue}</TechItem>
        ))}
      </TechList>
      <div
        style={{
          padding: window.innerWidth < 768 ? '20px 0px' : '20px 50px 90px 20px',
        }}
      >
        <Summary>{selectedProject.summary}</Summary>

        <ContentContainer>
          <ul
            style={{
              background: '#192247',
              padding: '15px 50px',
              fontFamily: 'Roboto',
              borderRadius: '5px',
            }}
          >
            {selectedProject.missions.map((content, index) => (
              <li
                style={{
                  padding: '10px 20px',
                }}
              >
                {content}
              </li>
            ))}
          </ul>
        </ContentContainer>

        {/* Carousel des images du projet */}
        {selectedProject.images && selectedProject.images.length > 0 && (
          <CarouselContainer>
            <Swiper
              modules={[Navigation, Pagination, Autoplay]}
              spaceBetween={60}
              slidesPerView={selectedProject.images.length > 3 ? 3.5 : 2}
              centeredSlides={selectedProject.images.length > 3 ? true : false}
              navigation
              autoplay={{
                delay: 3500, // Autoplay avec un délai de 2500ms
                disableOnInteraction: false, // Continuer après interaction
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1, // Pour les écrans de petite taille (mobile)
                },
                768: {
                  slidesPerView: selectedProject.images.length > 3 ? 3.5 : 2, // Pour les écrans de taille moyenne et plus (tablette, desktop)
                },
              }}
              onSlideChange={() => console.log('slide change')}
              onSwiper={(swiper) => console.log(swiper)}
              style={{ height: '300px', margin: '0px 80px' }}
            >
              {selectedProject.images.map((image, index) => (
                <SwiperSlide key={index}>
                  <img src={image} alt={`Image du projet ${index + 1}`} />
                </SwiperSlide>
              ))}
            </Swiper>
          </CarouselContainer>
        )}

        {selectedProject.button && selectedProject.button.src.length > 0 && (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <Button
              src={selectedProject.button.src}
              style={{
                display: window.innerWidth < 768 ? 'block' : 'inline-block',
              }}
            >
              {selectedProject.button.text}
            </Button>
          </div>
        )}
      </div>
    </ProjectDetailContainer>
  )
}

export default ProjectDetail
