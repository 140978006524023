import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import './i18n'
import './index.css'
import Home from './pages/Home/Home'
import { useTranslation } from 'react-i18next'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import Error from './components/Error/Error'
import Contact from './pages/Contact/Contact'
import Formations from './pages/Formations/Formations'
import Experiences from './pages/Experiences/Experiences'
import Profil from './pages/Profil/Profil'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Projects from './pages/Projets/Projects'
import ProjectDetail from './pages/Projets/ProjectDetail'
import avatarImage from './assets/images/avatar.png'

const App = () => {
  const { i18n } = useTranslation() // Ajoutez useTranslation pour i18n
  const [selectedLang, setSelectedLang] = useState('fr')
  const [profilePic, setProfilePic] = useState(avatarImage) // Initialisation de l'image de profil

  // Fonction pour changer la langue
  const handleLanguageChange = (lng) => {
    setSelectedLang(lng)
    i18n.changeLanguage(lng)
    localStorage.setItem('language', lng)
  }

  return (
    <Router>
      <Header
        profilePic={profilePic}
        setProfilePic={setProfilePic}
        selectedLang={selectedLang}
        setLang={handleLanguageChange}
      />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/experiences" element={<Experiences />} />
        <Route path="/formations" element={<Formations />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/projects/:id" element={<ProjectDetail />} />
        <Route path="/contact" element={<Contact />} />
        <Route
          path="/profil"
          element={
            <Profil
              profilePic={profilePic}
              setProfilePic={setProfilePic}
              selectedLang={selectedLang}
              setLang={handleLanguageChange}
            />
          }
        />{' '}
        {/* Passer les props au Profil */}
        <Route path="*" element={<Error />} />
      </Routes>
      <Footer />
    </Router>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
