import React from 'react'
import styled from 'styled-components'
import GraduationCapIcon from '@mui/icons-material/School' // Importer l'icône de chapeau de diplômé
import AssignmentIcon from '@mui/icons-material/Assignment'
import { useTranslation } from 'react-i18next'

// Styles généraux pour les titres
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
`

const Title = styled.h2`
  color: black;
  font-size: 36px;
  margin: 0;
  padding-right: 10px;
`

const Line = styled.div`
  height: 2px;
  background-color: #ccc;
  margin-top: 2px;
`

const ListContainer = styled.div`
  margin: 20px 0;
`

const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
`

const Date = styled.div`
  font-weight: bold;
  flex: 0 0 150px;
  @media (max-width: 768px) {
    flex: 0 0 40px;
  }
`

const DetailsContainer = styled.div`
  flex: 1;
  padding-left: 20px;
`

const Name = styled.div`
  font-weight: bold;
`

const Mention = styled.div`
  font-size: 14px;
  color: gray;
  font-style: italic;
`

const Organization = styled.div`
  font-size: 14px;
  color: #999;
`

function Formations() {
  const { t } = useTranslation() // Hook pour accéder aux traductions
  return (
    <div
      style={{
        backgroundColor: 'white',
        padding: window.innerWidth < 768 ? '40px 20px' : '20px 60px',
      }}
    >
      {/* Fond blanc pour la page */}
      {/* Titre Formations */}
      <TitleContainer>
        <Title>Formations</Title>
        <GraduationCapIcon
          style={{
            color: 'red',
            fontSize: '24px',
            marginLeft: '10px',
            verticalAlign: 'baseline',
          }}
        />
      </TitleContainer>
      <Line />

      {/* Liste des Formations */}
      <ListContainer>
        <ItemContainer>
          <Date>2021</Date>
          <DetailsContainer>
            <Name>{t('formations.master')}</Name>
            <Mention>{t('formations.mentionBien')}</Mention>
            <Organization>{t('school.cs2iLorient')}</Organization>
          </DetailsContainer>
        </ItemContainer>

        <ItemContainer>
          <Date>2019</Date>
          <DetailsContainer>
            <Name>{t('formations.licence')}</Name>
            <Mention>{t('formations.mentionBien')}</Mention>
            <Organization>{t('school.cs2iLorient')}</Organization>
          </DetailsContainer>
        </ItemContainer>

        <ItemContainer>
          <Date>2018</Date>
          <DetailsContainer>
            <Name>{t('formations.bts')}</Name>
            <Mention>{t('formations.mentionBien')}</Mention>
            <Organization>{t('school.gretaQuimper')}</Organization>
          </DetailsContainer>
        </ItemContainer>

        <ItemContainer>
          <Date>2016</Date>
          <DetailsContainer>
            <Name>{t('formations.de')}</Name>
            <Organization>{t('school.ifmem')}</Organization>
          </DetailsContainer>
        </ItemContainer>
      </ListContainer>
      {/* Titre Certifications */}
      <TitleContainer>
        <Title>{t('formations.certificationsTitle')}</Title>
        <AssignmentIcon
          style={{
            color: 'red',
            fontSize: '22px',
            marginLeft: '10px',
            verticalAlign: 'baseline',
          }}
        />
      </TitleContainer>
      <Line />
      {/* Liste des Certifications */}
      <ListContainer>
        <ItemContainer>
          <Date>2021</Date>
          <DetailsContainer>
            <Name>{t('formations.toeic')}</Name>
            <Mention>895 {t('formations.pts')} B2</Mention>
            <Organization>{t('school.cciLorient')}</Organization>
          </DetailsContainer>
        </ItemContainer>
      </ListContainer>
    </div>
  )
}

export default Formations
